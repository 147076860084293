// ============================================================================
//  _____ _____ __    __    _____ _____ __ __ _____
// | __  |  |  |  |  |  |  |   __|   __|  |  |   __|
// | __ -|  |  |  |__|  |__|__   |   __|_   _|   __|
// |_____|_____|_____|_____|_____|_____| |_| |_____|
//  aim on branding ®
// ============================================================================
'use strict';

window.addEventListener('load', function () {
    var orientation = 0;

    // application canvas
    var canvas = document.getElementById('App');

    // canvas 2d context
    var context = canvas.getContext('2d');

    // download button handler
    var save = document.getElementById('save');
    save.addEventListener('click', function(event) {
        event = event || window.event;
        event.preventDefault();

        canvas.toBlob(function(blob) {
            saveAs(blob, 'juska.png');
        });
    });

    // photo
    var image = document.createElement('img');
    image.addEventListener('load', function() {
        var width = image.width;
        var height = image.height;
        var x = 0;
        var y = 0;

        if (width < height) {
            height = 1600 * height / width;
            width = 1600;
        } else {
            width = 1600 * width / height;
            height = 1600;
        }

        context.clearRect(0, 0, 1600, 1600);
        context.save();

        switch (orientation) {
        case 2:
            // horizontal flip
            context.translate(1600, 0);
            context.scale(-1, 1);
            break;
        case 3:
            // 180° rotate left
            context.translate(1600, 1600);
            context.rotate(Math.PI);
            break;
        case 4:
            // vertical flip
            context.translate(0, 1600);
            context.scale(1, -1);
            break;
        case 5:
            // vertical flip + 90 rotate right
            context.rotate(0.5 * Math.PI);
            context.scale(1, -1);
            break;
        case 6:
            // 90° rotate right
            context.rotate(0.5 * Math.PI);
            context.translate(0, -1600);
            break;
        case 7:
            // horizontal flip + 90 rotate right
            context.rotate(0.5 * Math.PI);
            context.translate(1600, -1600);
            context.scale(-1, 1);
            break;
        case 8:
            // 90° rotate left
            context.rotate(-0.5 * Math.PI);
            context.translate(-1600, 0);
            break;
        }

        if (width > 1600) {
            x = (width - 1600) / -2;
        } else if (height > 1600) {
            y = (height - 1600) / -2;
        }

        context.drawImage(image, x, y, width, height);

        context.restore();
        context.drawImage(overlay, 0, 0, 1600, 1600);

        save.focus();
    });

    // image overaly
    var overlay = document.createElement('img');
    overlay.src = 'assets/front.png';

    // default image
    var default_image = document.createElement('img');
    default_image.addEventListener('load', function() {
        context.clearRect(0, 0, 1600, 1600);
        context.drawImage(default_image, 0, 0, 1600, 1600);
        document.body.classList.add('loaded');
    });

    // if (window.innerWidth > 1024) {
    default_image.src = 'assets/default2.jpg';
    // } else {
    //     default_image.src = 'assets/default.png';
    // }

    // button to upload image
    var upload = document.getElementById('input');
    upload.addEventListener('change', function(event) {
        var target;
        var file;

        event = event || window;
        target = event.target;

        if (!target || !target.files || target.files.length != 1) {
            return;
        }

        file = target.files[0];
        if (!file.type || file.type.indexOf('image/') != 0) {
            return;
        }

        var fr = new FileReader;
        fr.addEventListener('loadend', function() {
            var exif = EXIF.readFromBinaryFile(this.result);
            orientation = exif.Orientation;
            console.log(orientation);
            image.setAttribute("src", URL.createObjectURL(file));
        });

        fr.readAsArrayBuffer(file);
    });
});